@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  :root {
    --bgWhiteSD: #ffffff;
    --textWhiteSD: #ffffff;
    --borderWhiteSD: #ffffff;
    --bgBlackSD: #000000;
    --textBlackSD: #000000;
    --borderBlackSD: #000000;
    --textDark: #2e3234;
    --textSecondary: #797c7d;
    --textTertiary: #9b9d9e;
    --textWarningDark: #c6690d;
    --textDanger: #d32f2f;
    --textDangerDark: #a92626;
    --textSuccess: #24bd63;
    --textSuccessDark: #1d974f;
    --primaryColor: #6558f5;
    --primaryColorDark: #6558f5;
    --primaryDSD: #6558f5;
    --primaryDSD100: #e8f1f8;
    --primaryDSD200: #d1e4f1;
    --primaryDSD300: #a2c8e3;
    --primaryDSD400: #74add6;
    --primaryDSD500: #4591c8;
    --primaryDSD700: #125e95;
    --primaryDSD800: #0e4770;
    --primaryDSD900: #092f4a;
    --textGreen: #0a2c18;
    --primaryDSD1000: #051825;
    --softRecessShadow: 1px 1px 3px 0px #ececece5 inset,
      -1px -1px 2px 0px #ffffffe5 inset, 1px -1px 2px 0px #ececec33 inset,
      -1px 1px 2px 0px #ececec33 inset, -1px -1px 2px 0px #ececec80,
      1px 1px 2px 0px #ffffff4d;
    --recessShadow: 1px 1px 3px 0px #cccac6e5 inset,
      -1px -1px 2px 0px #ffffffe5 inset, 1px -1px 2px 0px #cccac633 inset,
      -1px 1px 2px 0px #cccac633 inset;
    --raiseShadow: 1px 1px 3px 0px #cfcfcfe5, -1px -1px 2px 0px #ffffffe5,
      1px -1px 2px 0px #cfcfcf33, -1px 1px 2px 0px #cfcfcf33;
    --mediumFloatShadow: 0px 0px 0px 0px #0000000a, 4px 5px 14px 0px #0000000a,
      5px 8px 20px 0px #0000000f, 37px 45px 35px 0px #00000005,
      65px 85px 41px 0px #00000003, 102px 125px 45px 0px #0000;
    --highFloatShadow: 0px 0px 0px 0px #0000000a, 4px 5px 14px 0px #0000000a,
      16px 20px 26px 0px #0000000a, 37px 25px 30px 0px #00000005,
      65px 30px 35px 0px #00000003;
    --toggleSwitchShadow: 1px 1px 5px 0px #4c60e96b;
  }

  /* comman-css */
  html,
  body {
    @apply m-0 p-0 font-Poppins font-normal;
  }

  * {
    @apply box-border break-words m-0 p-0 leading-normal;
  }

  .feather__icon {
    @apply w-full h-full;
  }

  .error__message {
    @apply text-[13px] text-textDanger mt-[5px];
  }

  .avatar svg {
    @apply w-full h-full text-textBlackSD relative z-[2];
  }

  /* Main wrapper uses flexbox to arrange content and sidebar */
  .main__wrapper {
    @apply flex h-[100vh];
  }

  /* Ensure the content wrapper takes up the remaining space */
  .main__wrapper .main__content__wrapper {
    @apply flex-1 bg-white py-[20px] px-[12px] sm:px-1 h-[100dvh] transition duration-500 ease-in-out;

  }

  /* Sidebar width when expanded (e.g., 270px) */

  .sidebar__collapse .main_content {
    @apply w-[calc(100%-90px)] !duration-300 !ease-in-out;
  }

  .main__wrapper .sidebar-wrapper {
    @apply w-[270px];
    /* Fixed width for the primary sidebar */
    transition: width 0.3s ease;
    /* Smooth transition for sidebar resizing */
  }

  /* When the sidebar is collapsed (e.g., 66px) */
  .main__wrapper.sidebar__collapse .sidebar-wrapper {
    @apply w-[66px];
    /* Reduced width for collapsed sidebar */
  }

  /* Sidebar 2 (secondary) */
  .main__wrapper .secondary-sidebar-wrapper {
    @apply w-[300px];
    /* Adjust this width based on your design */
    transition: width 0.3s ease;
  }

  .main__wrapper.sidebar__collapse .secondary-sidebar-wrapper {
    @apply w-0;
  }


  /* toast-css */
  .toast__wrapper__SD {
    @apply fixed w-[400px] max-w-[calc(100%_-_30px)] max-h-[calc(100dvh_-_60px)] bottom-[15px] right-[15px] z-[14] overflow-y-auto p-[10px];
  }

  .toast__wrapper__SD .toast__CN__absolute {
    @apply w-full;
  }

  .toast__SD__item {
    @apply w-full max-w-full rounded-[10px] bg-bgWhiteSD relative border-[1px] border-bgBlackSD shadow-raiseShadow mb-[12px] opacity-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[10px] before:opacity-20 before:bg-transparent last:mb-0;
  }

  .toast__SD__item.show {
    @apply opacity-0 animate-toast__show;
  }

  .toast__SD__item .inner__wrapper {
    @apply py-[7px] px-[12px] pr-[7px] flex items-center relative z-[2];
  }

  .toast__SD__item .toast__text {
    @apply text-[14px] font-medium text-textDark w-full leading-[20px];
  }

  .toast__SD__item .typeIcon {
    @apply w-[20px] h-[20px] mr-[12px] shrink-0;
  }

  .toast__SD__item .typeIcon svg {
    @apply w-full h-full;
  }

  .toast__SD__item .closeBtn {
    @apply w-[26px] h-[26px] p-[3px] duration-300 hover:bg-gray-100 shrink-0 rounded-full cursor-pointer ml-[8px];
  }

  .toast__SD__item .closeBtn svg {
    @apply text-textDark w-full h-full;
  }

  .toast__SD__item.success {
    @apply border-textSuccess before:bg-textSuccess;
  }

  .toast__SD__item.success .toast__text {
    @apply text-textSuccess;
  }

  .toast__SD__item.success .typeIcon svg {
    @apply text-textSuccess;
  }

  .toast__SD__item.success .closeBtn {
    @apply hover:bg-bgWhiteSD;
  }

  .toast__SD__item.error {
    @apply border-textDanger before:bg-textDanger;
  }

  .toast__SD__item.error .toast__text {
    @apply text-textDanger;
  }

  .toast__SD__item.error .typeIcon svg {
    @apply text-textDanger;
  }

  .toast__SD__item.error .closeBtn {
    @apply hover:bg-bgWhiteSD;
  }

  .toast__SD__item.warning {
    @apply border-textWarning before:bg-textWarning;
  }

  .toast__SD__item.warning .toast__text {
    @apply text-textWarning;
  }

  .toast__SD__item.warning .typeIcon svg {
    @apply text-textWarning;
  }

  .toast__SD__item.warning .closeBtn {
    @apply hover:bg-bgWhiteSD;
  }

  /* toast-css-end */

  /* button-css */
  /* buttons */
  .button__DSD {
    @apply bg-bgWhiteSD inline-flex items-center justify-center rounded-[7px] text-[14px] font-medium px-[24px] text-center leading-[14px] py-[7px] pb-[9px] duration-300 relative before:content-[''] before:absolute before:top-0 before:left-0 before:rounded-[7px] before:w-full before:h-full before:bg-transparent before:duration-300 after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:border-[1px] after:border-transparent after:rounded-[7px] after:z-[2] after:duration-300;
  }

  .button__DSD .btn__text {
    @apply relative top-[1px] z-[2] duration-300;
  }

  .button__DSD .small__loader__sd {
    @apply mr-[8px] p-[5px] top-[-1px];
  }

  /* primary__Btn */
  .button__DSD.primary__Btn {
    @apply before:bg-primaryColor hover:before:bg-primaryColorDark;
  }

  .button__DSD.primary__Btn .btn__text {
    @apply text-[#ffffff];
  }

  .button__DSD.primary__Btn .small__loader__sd {
    @apply border-[#ffffff60] border-r-[#ffffff];
  }

  /* primary__Btn__end */

  /* primary__secondary__Btn */
  .button__DSD.primary__secondary__Btn {
    @apply before:bg-primaryColor before:opacity-20;
  }

  .button__DSD.primary__secondary__Btn .btn__text {
    @apply text-primaryColor;
  }

  .button__DSD.primary__secondary__Btn:hover .btn__text {
    @apply text-primaryColorDark;
  }

  .button__DSD.primary__secondary__Btn .small__loader__sd {
    @apply border-[#ffffff] border-r-primaryColor;
  }

  /* primary__secondary__Btn__end */

  /* primary__outline__Btn */
  .button__DSD.primary__outline__Btn {
    @apply after:border-primaryColor before:bg-bgWhiteSD hover:after:border-primaryColorDark;
  }

  .button__DSD.primary__outline__Btn .btn__text {
    @apply text-primaryColor;
  }

  .button__DSD.primary__outline__Btn:hover .btn__text {
    @apply text-primaryColorDark;
  }

  .button__DSD.primary__outline__Btn .small__loader__sd {
    @apply border-gray-200 border-r-primaryColor;
  }

  /* primary__outline__Btn__end */

  /* primary__outlineTrans__Btn */
  .button__DSD.primary__outlineTrans__Btn {
    @apply bg-transparent after:border-primaryColor hover:after:border-primaryColorDark;
  }

  .button__DSD.primary__outlineTrans__Btn .btn__text {
    @apply text-primaryColor;
  }

  .button__DSD.primary__outlineTrans__Btn:hover .btn__text {
    @apply text-primaryColorDark;
  }

  .button__DSD.primary__outlineTrans__Btn .small__loader__sd {
    @apply border-gray-200 border-r-primaryColor;
  }

  /* primary__outline__Btn__end */

  /* primary__ghost__Btn */
  .button__DSD.primary__ghost__Btn {
    @apply bg-transparent;
  }

  .button__DSD.primary__ghost__Btn .btn__text {
    @apply text-primaryColor;
  }

  .button__DSD.primary__ghost__Btn:hover .btn__text {
    @apply text-primaryColorDark;
  }

  .button__DSD.primary__ghost__Btn .small__loader__sd {
    @apply border-gray-200 border-r-primaryColor;
  }

  /* primary__ghost__Btn__end */

  /* danger__Btn */
  .button__DSD.danger__Btn {
    @apply before:bg-textDanger hover:before:bg-textDangerDark;
  }

  .button__DSD.danger__Btn .btn__text {
    @apply text-[#ffffff];
  }

  .button__DSD.danger__Btn .small__loader__sd {
    @apply border-[#ffffff60] border-r-[#ffffff];
  }

  /* primary__Btn__end */

  /* danger__secondary__Btn */
  .button__DSD.danger__secondary__Btn {
    @apply before:bg-textDanger before:opacity-20;
  }

  .button__DSD.danger__secondary__Btn .btn__text {
    @apply text-textDanger;
  }

  .button__DSD.danger__secondary__Btn:hover .btn__text {
    @apply text-textDangerDark;
  }

  .button__DSD.danger__secondary__Btn .small__loader__sd {
    @apply border-[#ffffff] border-r-textDanger;
  }

  /* danger__secondary__Btn__end */

  /* danger__outline__Btn */
  .button__DSD.danger__outline__Btn {
    @apply after:border-textDanger before:bg-bgWhiteSD hover:after:border-textDangerDark;
  }

  .button__DSD.danger__outline__Btn .btn__text {
    @apply text-textDanger;
  }

  .button__DSD.danger__outline__Btn:hover .btn__text {
    @apply text-textDangerDark;
  }

  .button__DSD.danger__outline__Btn .small__loader__sd {
    @apply border-gray-200 border-r-textDanger;
  }

  /* danger__outline__Btn__end */

  /* danger__outlineTrans__Btn */
  .button__DSD.danger__outlineTrans__Btn {
    @apply bg-transparent after:border-textDanger hover:after:border-textDangerDark;
  }

  .button__DSD.danger__outlineTrans__Btn .btn__text {
    @apply text-textDanger;
  }

  .button__DSD.danger__outlineTrans__Btn:hover .btn__text {
    @apply text-textDangerDark;
  }

  .button__DSD.danger__outlineTrans__Btn .small__loader__sd {
    @apply border-gray-200 border-r-textDanger;
  }

  /* danger__outlineTrans__Btn__end */

  /* danger__ghost__Btn */
  .button__DSD.danger__ghost__Btn {
    @apply bg-transparent;
  }

  .button__DSD.danger__ghost__Btn .btn__text {
    @apply text-textDanger;
  }

  .button__DSD.danger__ghost__Btn:hover .btn__text {
    @apply text-textDangerDark;
  }

  .button__DSD.danger__ghost__Btn .small__loader__sd {
    @apply border-gray-200 border-r-textDanger;
  }

  /* primary__ghost__Btn__end */

  /* disable__Btn */
  .button__DSD.disable__Btn {
    @apply pointer-events-none before:bg-primaryColorDark before:opacity-10;
  }

  .button__DSD.disable__Btn .btn__text {
    @apply text-primaryColor opacity-50;
  }

  /* disable__Btn__end */

  /* disable__ghost__Btn */
  .button__DSD.disable__ghost__Btn {
    @apply pointer-events-none bg-transparent;
  }

  .button__DSD.disable__ghost__Btn .btn__text {
    @apply text-gray-200 opacity-30;
  }

  /* disable__Btn__end */

  /* danger__Btn */
  .button__DSD.danger__Btn {
    @apply before:bg-textDanger hover:before:bg-textDangerDark;
  }

  .button__DSD.danger__Btn .btn__text {
    @apply text-[#ffffff];
  }

  /* danger__Btn__end */
  /* buttons-end */

  /* form-css */
  .field__wrapper {
    @apply mb-[20px] w-full;
  }

  .field__wrapper .label__DSD {
    @apply text-textDark flex font-normal mb-[7px] text-[14px] w-full;
  }

  .field__wrapper .label__DSD .required__sign {
    @apply text-textDanger inline-block ml-[4px];
  }

  .input__DSD {
    @apply w-full text-[14px] font-normal text-textDark h-[40px] bg-bgWhiteSD rounded-[10px] pt-[6px] pb-[5px] px-[12px] border border-[#c5ced6] border-solid placeholder:text-gray-400 placeholder:opacity-100 hover:outline hover:outline-1 hover:-outline-offset-[1px] hover:outline-primaryColor focus:outline focus:outline-1 focus:-outline-offset-[1px] focus:outline-[#6558F5] focus:border-0;
  }

  .field__has__icon {
    @apply relative;
  }

  .field__has__icon .input__DSD {
    @apply pr-[42px];
  }

  .field__has__icon .icon__wrapper {
    @apply absolute right-[10px] top-[8px];
  }

  .field__has__icon .icon__wrapper {
    @apply w-[22px] h-[22px];
  }

  .field__has__icon .icon__wrapper svg {
    @apply w-full h-full;
  }

  /* .field__has__icon.icon__position__left .input__DSD {
    @apply pr-[12px] pl-[42px];
  } */

  .field__has__icon.icon__position__left .icon__wrapper {
    @apply right-unset left-[10px];
  }

  /* select-box */
  .select__SD {
    @apply w-full;
  }

  .select__SD div[class$="control"],
  .select__SD div[class$="control"]:hover,
  .select__SD .select__SD__control,
  .select__SD .select__SD__control:hover {
    @apply min-h-[40px] rounded-[10px] bg-bgWhiteSD pt-[4px] pb-[3px] pl-[10px] pr-[38px] shadow-raiseShadow border-[1px] border-transparent;
  }

  .select__SD div[class$="ValueContainer"],
  .select__SD .select__SD__value-container {
    @apply relative w-full p-0 pr-[10px];
  }

  .select__SD div[class$="placeholder"],
  .select__SD .select__SD__placeholder {
    @apply text-[14px] font-normal text-textSecondary opacity-100 whitespace-pre overflow-hidden text-ellipsis;
  }

  .select__SD div[class$="singleValue"],
  .select__SD .select__SD__single-value {
    @apply text-[14px] leading-[29px] font-normal text-textDark opacity-100 whitespace-pre overflow-hidden text-ellipsis;
  }

  .select__SD div[class$="Input"],
  .select__SD .select__SD__input,
  .select__SD .select__SD__input-container {
    @apply absolute top-0 left-0 m-0 h-[30px] w-full p-0 !font-normal !text-[14px] !text-textDark;
  }

  .select__SD span[class$="indicatorSeparator"],
  .select__SD .select__SD__indicator-separator {
    @apply hidden;
  }

  .select__SD div[class$="IndicatorsContainer"],
  .select__SD .select__SD__indicators {
    @apply mr-[-28px] cursor-pointer;
  }

  .select__SD div[class$="indicatorContainer"]:last-child,
  .select__SD .select__SD__indicator-separator:last-child {
    @apply p-0 w-[20px] h-[20px] relative before:content-[''] before:absolute before:w-[9px] before:h-[2px] before:bg-textSecondary before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_3px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[9px] after:h-[2px] after:bg-textSecondary after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_+_3px)] after:translate-x-[-50%] after:translate-y-[-50%] hover:before:bg-textDark hover:after:bg-textDark;
  }

  .select__SD div[class$="indicatorContainer"]:nth-last-of-type(2),
  .select__SD .select__SD__indicator-separator:nth-last-of-type(2) {
    @apply p-0 w-[20px] h-[20px] relative cursor-pointer before:content-[''] before:absolute before:w-[12px] before:h-[2px] before:bg-textSecondary hover:before:bg-textDark before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_1px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[12px] after:h-[2px] after:bg-textSecondary hover:after:bg-textDark after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_-_1px)] after:translate-x-[-50%] after:translate-y-[-50%];
  }

  .select__SD div[class$="indicatorContainer"] svg,
  .select__SD .select__SD__indicator-separator svg {
    @apply !opacity-0;
  }

  .select__SD svg[class$="Svg"],
  .select__SD .select__SD__svg {
    @apply fill-textDark opacity-0;
  }

  .select__SD .select__SD__control.select__SD__control--menu-is-open {
    @apply !outline !outline-1 !outline-primaryColor;
  }

  .select__SD .select__SD__control.select__SD__control--menu-is-open,
  .select__SD .select__SD__control.select__SD__control--menu-is-open:hover {
    @apply pt-[4px] pb-[3px];
  }

  .select__SD .select__SD__menu {
    @apply shadow-raiseShadow rounded-[10px] mb-0 mt-[5px] z-[5];
  }

  .select__SD .select__SD__menu-portal {
    @apply z-[5];
  }

  .select__SD .select__SD__menu-list {
    @apply p-[6px] rounded-[8px] overflow-x-hidden overflow-y-auto max-h-[250px];
  }

  .select__SD .select__SD__option {
    @apply font-normal text-[14px] text-textSecondary border-b-[1px] border-b-gray-200 py-[7px] px-[8px] cursor-pointer last:border-b-0 first:rounded-t-[8px] last:rounded-b-[8px];
  }

  .select__SD .select__SD__option.select__SD__option--is-focused {
    @apply bg-primaryDSD100;
  }

  .select__SD .select__SD__option.select__SD__option--is-selected,
  .select__SD .select__SD__option.select__SD__option--is-selected.select__SD__option--is-focused {
    @apply bg-primaryColor text-[#ffffff];
  }

  .select__SD .select__SD__menu-notice.select__SD__menu-notice--no-options {
    @apply text-[16px] font-normal text-textSecondary py-[8px] px-[12px] text-center;
  }

  /* multi-value-select-box */
  .select__SD .select__SD__value-container.select__SD__value-container--is-multi {
    @apply h-auto static flex w-full items-center p-0 pr-[10px];
  }

  .select__SD .select__SD__value-container.select__SD__value-container--is-multi .select__SD__input-container {
    @apply absolute m-0 p-0 left-0 top-[4px] w-full h-[30px] pl-[14px] pr-[54px] whitespace-pre overflow-hidden text-ellipsis inline-block;
  }

  .select__SD .select__SD__value-container.select__SD__value-container--is-multi .select__SD__input-container .select__SD__input {
    @apply static max-w-full;
  }

  .select__SD .select__SD__value-container.select__SD__value-container--is-multi.select__SD__value-container--has-value .select__SD__input-container {
    @apply static w-auto max-w-full pl-0 pr-0 inline-grid;
  }

  .select__SD .select__SD__value-container.select__SD__value-container--is-multi.select__SD__value-container--has-value .select__SD__input-container .select__SD__input {
    @apply relative max-w-full;
  }

  .select__SD .select__SD__multi-value {
    @apply inline-flex m-0 mt-[1px] mb-[2px] mr-[4px] bg-bgWhiteSD relative rounded-[10px] py-[5px] pt-[7px] px-[10px] pr-[28px] border-[1px] border-primaryColor before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:rounded-[4px];
  }

  .select__SD .select__SD__multi-value .select__SD__multi-value__label {
    @apply relative z-[2] p-0 text-[14px] font-normal text-textDark leading-[12px] whitespace-pre overflow-hidden text-ellipsis max-w-[100px];
  }

  .select__SD .select__SD__multi-value .select__SD__multi-value__remove {
    @apply cursor-pointer z-[2] absolute top-[50%] right-[5px] translate-y-[-50%] p-0 w-[18px] h-[18px] rounded-full before:content-[''] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[50%] before:translate-x-[-50%] before:w-[10px] before:h-[1px] before:bg-textBlackSD before:rotate-45 after:content-[''] after:absolute after:top-[50%] after:translate-y-[-50%] after:left-[50%] after:translate-x-[-50%] after:w-[10px] after:h-[1px] after:bg-textBlackSD after:-rotate-45 duration-300 hover:bg-gray-200;
  }

  .select__SD .select__SD__multi-value .select__SD__multi-value__remove svg {
    @apply hidden;
  }

  /* multi-value-select-box-end */

  .field__wrapper .select__SD .field__has__icon div[class$="control"],
  .field__wrapper .select__SD .field__has__icon div[class$="control"]:hover,
  .field__wrapper .select__SD .field__has__icon .select__SD__control,
  .field__wrapper .select__SD .field__has__icon .select__SD__control:hover {
    @apply pl-[35px];
  }

  .field__wrapper .select__SD .field__has__icon .select__SD__value-container.select__SD__value-container--is-multi .select__SD__input-container {
    @apply pl-[36px];
  }

  .field__wrapper .select__SD .field__has__icon .select__SD__value-container.select__SD__value-container--is-multi.select__SD__value-container--has-value .select__SD__input-container {
    @apply pl-[3px];
  }

  .field__wrapper.field__has__error .select__SD__control {
    @apply !outline !outline-1 !outline-textDanger;
  }

  .field__wrapper.field__has__success .select__SD__control {
    @apply !outline !outline-1 !outline-textSuccess;
  }

  /* remove-down-arrow */
  .remove__downArrow .select__SD div[class$="IndicatorsContainer"],
  .remove__downArrow .select__SD .select__SD__indicators {
    @apply hidden;
  }

  .remove__downArrow .select__SD div[class$="control"],
  .remove__downArrow .select__SD div[class$="control"]:hover,
  .remove__downArrow .select__SD .select__SD__control,
  .remove__downArrow .select__SD .select__SD__control:hover {
    @apply pr-[10px];
  }

  .remove__downArrow .select__SD .select__SD__value-container.select__SD__value-container--is-multi {
    @apply pr-[0px];
  }

  /* remove-down-arrow-end */

  .upload__files__box .file__icon {
    @apply w-[64px] h-[64px] rounded-full flex items-center justify-center bg-gray-200 p-[14px] mx-auto mb-[15px];
  }

  .upload__files__box .file__icon svg {
    @apply w-full h-full text-textDark;
  }

  .upload__files__box .uploadFile__btn:hover .btn__text {
    @apply text-white;
  }

  /* country-dropdown */
  .field__wrapper .react-tel-input .form-control {
    @apply w-full border-0 text-[14px] font-normal text-textDark h-[40px] bg-bgWhiteSD rounded-[10px] pt-[6px] pb-[5px] pl-[56px] pr-[40px] shadow-recessShadow placeholder:text-textSecondary placeholder:opacity-100 hover:outline hover:outline-1 hover:outline-primaryColor focus:outline focus:outline-1 focus:outline-primaryColor focus:border-0 focus:outline-offset-0 focus:shadow-recessShadow;
  }

  .field__wrapper .react-tel-input .flag-dropdown {
    @apply !bg-transparent border-0;
  }

  .field__wrapper .react-tel-input .selected-flag {
    @apply before:hidden hover:bg-transparent;
  }

  .field__wrapper .react-tel-input .selected-flag .arrow {
    @apply border-l-[4px] border-l-transparent border-r-[4px] border-r-transparent border-t-[5px] border-t-textSecondary;
  }

  .field__wrapper .react-tel-input .selected-flag .arrow.up {
    @apply border-t-0 border-b-[5px] border-b-textSecondary;
  }

  .field__wrapper .react-tel-input .flag-dropdown.open {
    @apply z-[4];
  }

  .field__wrapper .react-tel-input .country-list {
    @apply shadow-mediumFloatShadow w-[263px] mb-0 mt-[3px] z-[4];
  }

  .field__wrapper .react-tel-input .country-list .country {
    @apply py-[7px] px-[12px] border-b-[1px] border-b-gray-200 flex items-center hover:bg-gray-200 last:border-b-0;
  }

  .field__wrapper .react-tel-input .country-list .flag {
    @apply static mr-[7px] mt-[-4px] shrink-0;
  }

  .field__wrapper .react-tel-input .country-list .country-name {
    @apply font-normal text-[14px] text-textDark whitespace-pre overflow-hidden text-ellipsis;
  }

  .field__wrapper .react-tel-input .country-list .dial-code {
    @apply font-normal text-[13px] text-textSecondary shrink-0;
  }


  .reactDataTable__DSD header {
    @apply !p-0;
  }

  .contentTable .rdt_TableRow .rdt_TableCell {
    @apply !w-[50px];
  }

  .contentTable .rdt_TableHead .rdt_TableCol:first-child,
  .contentTable .rdt_TableHead .rdt_TableCol:last-child,
  .meetingListNotes .rdt_TableHead .rdt_TableCol:last-child,
  .meetingListNotes .rdt_TableHead .rdt_TableCol:first-child {
    @apply !p-0 flex-[unset];
  }

  .meetingListNewNotes .rdt_TableHead .rdt_TableCol:last-child,
  .meetingListNewNotes .rdt_TableHead .rdt_TableCol:first-child {
    @apply !p-0 flex-[unset];
  }

  .contentTable .rdt_TableHead .rdt_TableCol:first-child,
  .patientListNotes .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .meetingListNotes .rdt_TableHead .rdt_TableCol:first-child {
    @apply !w-0 !min-w-0 flex-[unset] p-0;
  }

  .meetingListNewNotes .rdt_TableHead .rdt_TableCol:first-child {
    @apply !w-0 !min-w-0 flex-[unset] p-0;
  }

  .patientListNotes .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    @apply p-0;
  }

  .contentTable .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-200px)] overflow-auto;
  }


  .patientlist .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-210px)] overflow-auto xl:h-[calc(100dvh-260px)];
  }

  .contentTable.data-check .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-270px)] overflow-auto;
  }

  .patientlist.data-check .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-230px)] overflow-auto;
  }

  .meetinglist .rdt_Table .rdt_TableBody,
  .patientlist .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-180px)] overflow-auto 4xl:h-[calc(100dvh-260px)];
  }

  .patientListNotes .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-200px)] overflow-auto xl:h-[calc(100dvh-270px)];
  }

  .patientListNotes.data-check .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-230px)] overflow-auto xl:h-[calc(100dvh-300px)];
  }

  .rdt_TableRow {
    @apply !min-h-[auto]
  }

  .contentTable .rdt_Table .rdt_TableCell {
    @apply block;
  }

  .meetingListNotes .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-230px)] overflow-auto;
  }

  .meetingListNotes .data-check .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-250px)] overflow-auto;
  }

  .patientListNewNotes .rdt_Table .rdt_TableBody,
  .meetingListNewNotes .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-280px)] overflow-auto;
  }

  .patientListNewNotes .data-check .rdt_Table .rdt_TableBody,
  .meetingListNewNotes .data-check .rdt_Table .rdt_TableBody {
    @apply h-[calc(100dvh-250px)] overflow-auto;
  }

  .patienttable .rdt_Table {
    @apply overflow-y-auto rounded-[10px] pb-[5px] pr-[5px] bg-transparent max-h-full;
  }

  .reactDataTable__DSD .rdt_Table .rdt_TableRow {
    @apply min-h-[auto];
  }

  .reactDataTable__DSD.role__wrapper .rdt_TableBody .rdt_TableCell:first-child {
    @apply w-[80%] max-w-full flex-[unset];
  }

  .reactDataTable__DSD.role__wrapper .rdt_TableBody .rdt_TableCell:nth-child(2) {
    @apply w-[20%] max-w-full flex-[unset];
  }

  .reactDataTable__DSD.user__management__table .rdt_TableHead .rdt_TableCol:nth-child(5),
  .reactDataTable__DSD.user__management__table .rdt_TableBody .rdt_TableCell:nth-child(5) {
    @apply w-[200px] flex-[unset] min-w-[200px];
  }

  .reactDataTable__DSD .rdt_TableRow:last-child {
    @apply border-b-0;
  }

  .reactDataTable__DSD .edit__btn {
    @apply w-[30px] h-[30px] cursor-pointer rounded-full p-[6px] hover:bg-gray-200;
  }

  .reactDataTable__DSD .edit__btn svg {
    @apply w-full h-full;
  }

  .userlist__wrapper .rdt_TableBody {
    @apply !bg-transparent border-t-0 h-[calc(100dvh-220px)] overflow-y-auto;
  }

  .user__management__table .rdt_TableBody::-webkit-scrollbar,
  .playbookClass::-webkit-scrollbar,
  .drag__wrapper::-webkit-scrollbar,
  .content_wrapper::-webkit-scrollbar,
  .attachment__list::-webkit-scrollbar {
    @apply hidden;
  }

  .reactDataTable__DSD .rdt_Pagination {
    @apply !bg-transparent border-t-0 px-0 relative pl-[135px] flex-wrap text-right;
  }

  .reactDataTable__DSD .rdt_Pagination>span:nth-child(1) {
    @apply text-[16px] font-medium text-textSecondary mx-0 mr-[10px];
  }

  .reactDataTable__DSD .rdt_Pagination>*:nth-child(2) {
    @apply bg-bgWhiteSD rounded-[5px] shadow-raiseShadow;
  }

  .reactDataTable__DSD .rdt_Pagination>*:nth-child(2) select {
    @apply text-[16px] text-textSecondary py-[2px];
  }

  .reactDataTable__DSD .rdt_Pagination>*:nth-child(2) select:focus {
    @apply !border-0 !outline-0;
  }

  .reactDataTable__DSD .rdt_Pagination>*:nth-child(2) svg {
    @apply fill-textSecondary top-[2px];
  }

  .reactDataTable__DSD .rdt_Pagination>span:nth-child(3) {
    @apply text-[16px] font-medium text-textSecondary;
  }

  .reactDataTable__DSD .rdt_Pagination>*:last-child {
    @apply absolute top-[50%] translate-y-[-50%] left-0;
  }

  .reactDataTable__DSD .rdt_Pagination>*:last-child button {
    @apply w-[32px] h-[32px] p-[4px] mr-[5px] hover:bg-bgWhiteSD hover:shadow-raiseShadow last:mr-0;
  }

  .reactDataTable__DSD .rdt_Pagination>*:last-child button[disabled] {
    @apply opacity-50 pointer-events-none;
  }

  .reactDataTable__DSD .rdt_Pagination>*:last-child button svg {
    @apply fill-textDark w-full h-full;
  }

  .reactDataTable__DSD .ofEne {
    @apply text-[16px];
  }

  /* reactDataTable__DSD__end */

  /* modal-start */
  html.modal__open {
    @apply overflow-hidden;
  }

  .modal__wrapper__SD {
    @apply fixed h-screen top-0 left-0 z-[12] w-screen items-center justify-center flex;
  }

  .modal__wrapper__SD .modal__overlay__SD {
    @apply h-[100vh] pointer-events-none fixed top-0 bottom-0 left-0 right-0 w-screen bg-[#2e32341a] backdrop-blur-[8px];
  }

  .modal__wrapper__SD .modal__content__wrapper {
    @apply relative z-[2] max-w-[calc(100%_-_30px)] mx-auto rounded-[10px] bg-bgWhiteSD;
  }

  .modal__wrapper__SD .modal__header {
    @apply py-[12px] px-[15px] pr-[10px] relative z-[4] flex items-center justify-between bg-bgWhiteSD rounded-t-[10px] shadow-highFloatShadow;
  }

  .modal__wrapper__SD .modal__header .title {
    @apply text-[18px] font-medium text-textDark w-full pr-[20px];
  }

  .modal__wrapper__SD .modal__header .modal__close__btn {
    @apply shrink-0 flex items-center justify-center w-[28px] h-[28px] rounded-[6px] text-[0px] relative duration-300 p-[3px] before:content-[''] before:absolute before:top-[50%] before:left-[50%] before:translate-y-[-50%] before:translate-x-[-50%] before:w-[16px] before:h-[3px] before:bg-textDark before:rotate-45 before:duration-300 after:content-[''] after:absolute after:top-[50%] after:left-[50%] after:translate-y-[-50%] after:translate-x-[-50%] after:w-[16px] after:h-[3px] after:bg-textDark after:-rotate-45 after:duration-300 hover:before:bg-[#ffffff] hover:after:bg-[#ffffff] hover:shadow-raiseShadow before:hidden after:hidden;
  }

  .modal__wrapper__SD .modal__header .modal__close__btn svg {
    @apply text-textSecondary duration-300 w-full h-full;
  }

  .modal__wrapper__SD .modal__header .modal__close__btn:hover svg {
    @apply text-textBlackSD;
  }

  .modal__wrapper__SD .modal__body {
    @apply max-h-[calc(100dvh_-_225px)] overflow-y-auto py-[26px] pb-0 px-[20px];
  }

  .modal__wrapper__SD .modal__footer {
    @apply py-[18px] px-[24px] flex items-center justify-end;
  }

  .modal__wrapper__SD .modal__footer .button__SD {
    @apply ml-[10px] first:ml-0;
  }

  .recent__files__table .mainHeader svg {
    @apply w-[26px] h-[26px];
  }

  .recent__files__table__wrapper {
    @apply w-full;
  }

  .recent__files__table {
    @apply w-full;
  }

  .recent__files__table .recent__files__header {
    @apply bg-bgWhiteSD shadow-mediumFloatShadow rounded-t-[12px];
  }

  .recent__files__table .recent__files__row {
    @apply flex items-center bg-bgWhiteSD shadow-mediumFloatShadow rounded-[7px] mb-[15px] last:mb-0;
  }

  .recent__files__table .recent__files__row .recent__files__cell {
    @apply w-[15%] whitespace-pre overflow-hidden text-ellipsis py-[16px] px-[15px] text-[14px];
  }

  .recent__files__table .recent__files__header .recent__files__row .recent__files__cell {
    @apply font-semibold text-[16px];
  }

  .recent__files__table .recent__files__row .recent__files__cell.checkmark {
    @apply flex;
  }

  .recent__files__table .recent__files__row .recent__files__cell.checkmarkRed svg {
    @apply stroke-red-600;
  }

  .recent__files__table .recent__files__header .recent__files__row {
    @apply rounded-b-none rounded-t-[10px] mb-[1px] shadow-none;
  }

  .recent__files__table .recent__files__body .recent__files__row:first-child {
    @apply rounded-t-none;
  }

  .recent__files__table .toggle__btn {
    @apply w-[30px] h-[22px] bg-bgWhiteSD rounded-[5px] p-[0px] cursor-pointer hover:shadow-raiseShadow;
  }

  .recent__files__table .toggle__btn svg {
    @apply w-full h-full;
  }

  /* recent__files__table__end */
}

/* small loader css start  */
.small__loader__sd {
  @apply w-0 h-0 p-[7px] rounded-full border-[3px] border-gray-400 border-r-white relative animate-small__loader__sd;
}

.file__manager__onlyShow .upload__btn {
  @apply hidden;
}

.goZmTm {
  @apply cursor-pointer border-black bg-black;
}

.transcrip svg {
  @apply w-[17px] h-[17px];
}

.editname svg {
  @apply min-w-[20px];
}

.eyeicon svg {
  @apply ml-auto max-w-[20px];
}

.eyeicon svg path,
.eyeicon svg circle {
  @apply stroke-[#0e4770];
}

.dashboardaudio>div {
  min-height: unset;
}

.custominputfile::-webkit-file-upload-button {
  @apply absolute py-[3px] px-[20px] border-0 cursor-pointer;
}

.custom_table .recent__files__table .recent__files__header {
  @apply rounded-xl;
}

.custom_table .custom_table_row .recent__files__cell {
  @apply !font-normal !text-[14px];
}

.custom_table .toggle__btn {
  @apply w-fit;
}

.custom_table .toggle__btn svg {
  @apply max-w-[20px];
}

.filter-switch-item.bg-gray-500 label svg path,
.filter-switch-item.bg-gray-500 label svg circle {
  @apply stroke-white;
}

.shadow-raiseShadow .upload__files__box {
  @apply min-w-min;
}

.recentfiles_custom_table .recent__files__body .recent__files__row {
  @apply justify-between;
}

.recentfiles_custom_table .recent__files__row .recent__files__cell:nth-child(1) {
  @apply w-[40%];
}

.recentfiles_custom_table .recent__files__row .recent__files__cell:last-child,
.recentfiles_custom_table .recent__files__row .recent__files__cell:nth-last-child(2),
.recentfiles_custom_table .recent__files__row .recent__files__cell:nth-last-child(3) {
  @apply w-[10%];
}

.recentfiles_custom_table .recent__files__row input[type="checkbox"],
.custom-check {
  @apply ml-[10px] appearance-none w-4 min-w-[1rem] h-4 border border-black relative rounded bg-transparent cursor-pointer before:absolute before:right-1/2 before:top-1/2 before:w-[5px] before:h-[10px] before:border-r-2 before:border-b-2 before:border-white;
}

.recentfiles_custom_table .recent__files__row input[type="checkbox"]:before,
.custom-check::before {
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.recentfiles_custom_table .recent__files__row input[type="checkbox"]:checked,
.custom-check:checked {
  @apply bg-black;
}

.custom-check {
  @apply ml-1 mr-2;
}

.react-datepicker-popper {
  @apply !z-10;
}

.upcoming-appointment svg {
  @apply w-10 h-14;
}

.custom-select {
  @apply border-[2px] border-[#C3CFD9] rounded-[10px];
}

.custom-select .select__SD__control {
  @apply !shadow-none;
  box-shadow: none !important;
}

.summaryData h1,
.summaryData h2,
.summaryData h3,
.summaryData h4,
.summaryData h5,
.summaryData h6 {
  @apply !font-bold;
}

.summaryData p {
  @apply text-[18px] mb-3 sm:text-base;
}

.summaryData ul {
  @apply ml-5 pl-3 list-disc mb-6;
}

.summaryData ul li {
  @apply text-[15px];
}

.tippy-box .tippy__dropdown__ul .item__text span {
  @apply font-semibold text-[16px];
}

.sidebar__wrapper .logout__btn .s__Menu__Item__Link:hover .s__Menu__Icon svg {
  @apply !stroke-white;
}

.sidebar__wrapper .logout__btn .s__Menu__Item__Link:hover .s__Menu__Icon svg path,
.sidebar__wrapper .logout__btn .s__Menu__Item__Link:hover .s__Menu__Icon svg line {
  @apply !fill-none !stroke-white;
}

.switch {
  @apply inline-block h-[20px] relative w-[50px] min-w-[50px] sm:h-[15px] sm:w-[30px] sm:min-w-[30px];
}

.switch input {
  @apply hidden;
}

.switch .slider {
  @apply bg-[#eef3fd] rounded-[34px] bottom-0 cursor-pointer left-0 absolute right-0 top-0 transition-all duration-200 before:bg-[#4c60e9] before:rounded-full before:bottom-0 before:h-[20px] before:right-0 before:-translate-x-[0px] before:absolute before:w-[20px] sm:before:w-[13px] sm:before:h-[13px] sm:before:bottom-[1px] before:transition-all before:duration-200;
}

.switch input:checked+.slider:before {
  @apply bg-[#000] -translate-x-[30px] sm:-translate-x-[16px];
}

.inprogress_state,
.complete_state,
.failed_state,
.pending_state {
  @apply w-[22px];
}

.inprogress_state path {
  @apply fill-orange-400;
}

.complete_state path {
  @apply fill-green-400;
}

.failed_state path {
  @apply fill-red-400;
}

.pending_state path {
  @apply fill-yellow-400;
}

.patienttable .rdt_TableHead .rdt_TableCol,
.patienttable .rdt_Table .rdt_TableCell {
  @apply w-[15%];
  flex-basis: unset;
}

.patienttable .rdt_TableHead .rdt_TableCol:nth-child(2),
.patienttable .rdt_Table .rdt_TableCell:nth-child(2) {
  @apply w-[20%];
}

.patienttable .rdt_TableHead .rdt_TableCol:nth-last-child(1),
.patienttable .rdt_TableHead .rdt_TableCol:nth-last-child(2),
.patienttable .rdt_Table .rdt_TableCell:nth-last-child(1),
.patienttable .rdt_Table .rdt_TableCell:nth-last-child(2) {
  @apply w-[10%];
}

.patienttable .rdt_TableHead .rdt_TableCol:first-child,
.patienttable .rdt_Table .rdt_TableCell:first-child {
  @apply w-[40%] lg:w-[30%];
}

.audiowaves {
  @apply w-[calc(100%_-_100px)] md:w-full md:max-w-none relative before:absolute before:w-[2px] before:h-5 before:bg-[#9eadba] before:left-[5px] before:-top-[20px] after:absolute after:w-[2px] after:h-5 after:bg-[#9eadba] after:right-[5px] after:-top-[20px];
}

.audiowaves .wavesvalue {
  @apply flex justify-between items-center relative before:absolute before:w-[calc(100%_-_11px)] before:md:w-[calc(100%-_10px)] before:h-[2px] before:-top-[20px] before:left-1/2 before:-translate-x-1/2 before:bg-[#9eadba];
}

.audiowaves .wavesvalue div {
  @apply relative before:absolute before:-top-[20px] before:left-1/2 before:-translate-x-1/2 before:w-[2px] before:h-5 before:bg-[#9eadba];
}

.audiowaves .wavesvalue div:first-child,
.audiowaves .wavesvalue div:last-child {
  @apply before:hidden;
}

.transcriptmodal .field__wrapper {
  @apply !w-[200px] sm:w-fit;
}

.notesTable .rdt_TableHead .rdt_TableCol,
.notesTable .rdt_Table .rdt_TableCell {
  @apply w-[15%] basis-[unset] lg:basis-0 min-w-[auto] p-0 justify-center;
}

.notesTable .rdt_TableHead .rdt_TableCol {
  @apply justify-start;
}

.notesTable .rdt_TableHead .rdt_TableCol .rdt_TableCol_Sortable {
  @apply pl-[17px];
}

.rdt_TableCol_Sortable {
  @apply font-semibold;
}

.notesTable .rdt_Table .rdt_TableCell:last-child {
  @apply w-[10%] max-w-[10%];
}

.transcriptmodal .custom-select .select__SD__control {
  @apply bg-[#6558f50f];
}

.view-prompt-modal .modal__wrapper__SD {
  @apply h-[calc(100vh_-_100px)] top-unset bottom-0;
}

.notesTable .jTPWeN {
  @apply min-w-[900px];
}

.tiptap ul,
.tiptap ol {
  @apply px-4;
}

.tiptap ul {
  list-style-type: disc;
}

.tiptap ol {
  list-style-type: decimal;
}

.tiptap p {
  @apply m-0;
}

.tiptap ul li {
  @apply pl-2;
}

.tiptap ol li {
  @apply pl-2;
}

.tiptap ul ul {
  @apply list-[circle];
}

.tiptap ul ul ul {
  @apply list-[square];
}

.tiptap ul[data-type="taskList"] {
  @apply list-none m-2 pl-4;
}

.tiptap ul[data-type="taskList"] li {
  @apply flex;
}

.tiptap ul[data-type="taskList"] li label {
  @apply flex-none mr-2 cursor-pointer;
}

.tiptap ul[data-type="taskList"] li div {
  @apply flex-grow;
}

.tiptap ul[data-type="taskList"] li[data-checked="true"] p {
  @apply line-through opacity-50;
}

.tiptap h1 {
  @apply leading-[1.1] font-bold;
}

.tiptap h2 {
  @apply leading-[1.1] font-bold mt-0;
}

.tiptap h3 {
  @apply leading-[1.1] font-semibold;
}

.tiptap h4 {
  @apply leading-[1.1] font-medium;
}

.tiptap h5 {
  @apply leading-[1.1];
}

.tiptap h6 {
  @apply leading-[1.1];
}

.tiptap code {
  @apply bg-[#6161611a] text-[#616161];
}

.tiptap pre {
  @apply text-white px-4 py-3 rounded-lg;
  background: #0d0d0d;
  font-family: "JetBrainsMono", monospace;
}

.tiptap pre code {
  @apply text-inherit text-[0.8rem] p-0;
  background: none;
}

.tiptap img {
  @apply max-w-full h-auto;
}

.tiptap blockquote {
  @apply pl-4 border-l-2 border-l-[#0d0d0d1a] border-solid;
}

.tiptap hr {
  @apply mx-0 my-8 border-t-2 border-t-[#0d0d0d1a] border-[none] border-solid;
}

.playbook-editor::-webkit-scrollbar {
  @apply hidden;
}
.tiptap,
.ProseMirror {
  @apply p-3;
  height: -webkit-fill-available !important;
}

.ProseMirror:focus {
  @apply outline-none;
}

.play-icon path,
.pause-icon path,
.redo-icon path {
  fill: white;
}

.download-area:hover .download-icon path,
.trash-area:hover .trash-icon path {
  @apply transition-all duration-500;
}

.select-custom-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;
  appearance: none;
}

.download-area:hover .download-icon path {
  fill: var(--primaryColor);
}

.trash-area:hover .trash-icon path {
  fill: #ef4444;
}

.commands-list-container {
  margin: 0;
  padding: 4px 0;
  width: 256px;
  background: #fff;
  border: 1px solid #dcdbdd;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.commands-list-container header {
  border-bottom: 1px solid #dcdbdd;
  padding: 4px 16px 8px 16px;
  color: #6f6e77;
}

.commands-list-item {
  padding: 8px 16px;
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #1a1523;
  display: flex;
  gap: 8px;
}

.commands-list-item:hover,
.commands-list-item-active {
  background: #f4f2f4;
  cursor: pointer;
}

.commands-list-item svg {
  width: 16px;
  height: 16px;
}

.btn_with_color_fill {
  @apply text-[#6558F5] border border-[#6558F5] bg-white md:mt-4 hover:bg-[#6558F5] hover:text-white transition-all duration-100 ease-in-out;
}

.main__wrapper.close-sidemenu .main__content__wrapper {
  @apply sm:w-[calc(100%_-_66px)];
}

.close-sidemenu .collapse__btn {
  @apply sm:right-[-14px];
}

.main__wrapper.close-sidemenu .sidebar__wrapper {
  @apply sm:w-[66px];
}

.user-settings .rdt_TableRow>div:nth-child(2),
.user-settings .rdt_TableHeadRow>div:nth-child(2) {
  @apply lg:min-w-[100px] sm:min-w-[75px] sm:max-w-[75px] lg:max-w-[100px];
}

.content-notes ol li p {
  @apply !text-left;
}

.bottom-shadow {
  box-shadow: 0px 2px 1px 0px #cfcfcf45;
}

.patient-info-fields .field__wrapper {
  margin-bottom: 0;
}

.loader-svg .svg-elem-1 {
  stroke-dasharray: 496.2391052246094px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  animation: logo-anim 2s infinite;
}

.loader-svg .svg-elem-2 {
  stroke-dasharray: 246.72938537597656px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  animation: logo-anim2 2s infinite;
}

@keyframes logo-anim {
  0% {
    stroke-dashoffset: 496.2391052246094px;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: rgb(58, 84, 172);
  }
}

@keyframes logo-anim2 {
  0% {
    stroke-dashoffset: 246.72938537597656px;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: rgb(44, 46, 53);
  }
}

.billingtable .rdt_Table {
  @apply overflow-y-auto rounded-[10px] pb-[5px] pr-[5px] bg-transparent max-h-[calc(95dvh_-_400px)];
}

/* side bar */
.secondary__content__wrapper {
  @apply w-[25%] max-w-full 3xl:w-[30%] xl:w-[35%];
}

.notesTable .rdt_Table .rdt_TableBody .rdt_TableRow {
  @apply justify-between;
}

.reactDataTable__DSD .rdt_Table::-webkit-scrollbar {
  @apply hidden;
}

.notesTable .rdt_Table .rdt_TableCell {
  @apply justify-start;
}

.main_content:has(.secondary__content__wrapper) {
  @apply flex;
}

.notesTable .rdt_Table .rdt_TableCell:first-child {
  @apply justify-center;
}

.select__menu .btn-text {
  @apply w-full max-w-full;
}

.sidebar__collapse .side-menu .flex {
  @apply px-0;
}

.tablet-table::-webkit-scrollbar {
  @apply hidden;
}

.content-table .rdt_TableRow .rdt_TableCell {
  @apply pr-0;
}

.content-table .rdt_TableRow .rdt_TableCell:nth-child(2) {
  @apply pl-0;
}

.rdt_TableBody {
  @apply overflow-auto;
}

.promptslist__wrapper .rdt_Table .rdt_TableBody {
  @apply h-[calc(100dvh-270px)];
}


.content-table-data .sc-feUZmu::-webkit-scrollbar,
.role__permission .sc-feUZmu::-webkit-scrollbar,
.rdt_TableBody::-webkit-scrollbar,
.promptslist__wrapper .sc-feUZmu::-webkit-scrollbar,
.role__wrapper .sc-feUZmu::-webkit-scrollbar {
  @apply hidden;
}

.role__wrapper .rdt_TableBody {
  @apply h-[calc(100dvh-285px)] overflow-auto;
}

.role__wrapper .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  @apply w-[20%] flex-[unset];
}

.role__wrapper .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  @apply w-[80%] flex-[unset];
}


.invoices__wrapper .rdt_TableBody,
.superuserlist__wrapper .rdt_TableBody,
.role__permission .rdt_TableBody {
  @apply h-[calc(100dvh-270px)] overflow-auto;
}

.user__management__table .rdt_TableBody {
  @apply h-[calc(100dvh-260px)] overflow-auto;
}

.user__management__table .sc-feUZmu::-webkit-scrollbar {
  @apply hidden;
}

.play-book-table .rdt_TableBody {
  @apply h-[calc(100dvh-280px)] overflow-auto;
}

.user__management__table .rdt_Table .rdt_TableCell:nth-child(1) {
  @apply w-[calc(100%-50px)] max-w-full;
}

.user__management__table .rdt_Table .rdt_TableCell:last-child {
  @apply w-[50px] max-w-full flex-[unset];
}

.notes__delete .button__DSD {
  @apply transition-none before:transition-none;
}

.edit_profile .form__group {
  @apply w-[calc(100%-130px)] max-w-full;
}

.billingtable .rdt_TableBody {
  @apply h-[calc(100dvh-570px)] overflow-auto;
}

.subscription__price___content:has(.plan__three) {
  @apply max-w-[80%] xl:max-w-full
}

.subscription__price___content:has(.plan__five) {
  @apply flex-wrap;
}

.white-icon path{
  @apply fill-white;
}

.cost-calculator-user .disable__Btn{
  @apply before:bg-[#a9a9a9] ;
}

.cost-calculator-user .disable__Btn .btn__text{
  @apply text-textDark;
}

.no-edit .input__DSD {
  @apply border-0 hover:border-0 hover:outline-0 pl-2;
}

.base-cost .input__DSD{
  @apply h-[30px] max-w-[100px];
}

.cost-select .custom-select{
  @apply border-[1px];
}

.no-spinner {
  appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* For Firefox */
.no-spinner {
  -moz-appearance: textfield;
}

.bold-label .label__text {
  @apply font-medium text-base;
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker {
  @apply text-[#838383];
}